import { createTheme, alpha, type SimplePaletteColorOptions } from '@mui/material';

import { Colors } from './colors';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: SimplePaletteColorOptions;
    quaternary: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    tertiary?: SimplePaletteColorOptions;
    quaternary?: SimplePaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    underlined: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    tertiary: true;
    quaternary: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiary: true;
    quaternary: true;
  }
}

const borderRadius = 4;
export const themeBaseSpacing = 8;
export const themeZIndexes = {
  mobileStepper: 1000,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  popper: 6000,
  snackbar: 9001,
  tooltip: 9002,
};

export const defaultFontFamily = "'Rubik', sans-serif";

export const theme = createTheme({
  spacing: themeBaseSpacing,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: Colors.primary,
      light: Colors.lavender,
    },
    secondary: {
      main: Colors.storm,
      light: Colors.iron,
    },
    tertiary: {
      main: Colors.bluePastel,
      light: Colors.cloud,
    },
    quaternary: {
      main: Colors.fountain,
      light: Colors.fresco,
    },
    warning: {
      main: Colors.orange,
      light: Colors.dawn,
    },
    error: {
      main: Colors.red,
      light: Colors.strawberry,
    },
    info: {
      main: Colors.blue,
      light: Colors.sky,
    },
    success: {
      main: Colors.green,
      light: Colors.fresco,
      contrastText: Colors.white,
    },
    text: {
      primary: Colors.night,
      secondary: Colors.shark,
      disabled: Colors.iron,
    },
    divider: Colors.titanPurple,
    grey: {
      200: Colors.athens,
    },
  },
  shape: {
    borderRadius: borderRadius,
  },
  zIndex: themeZIndexes,
  typography: {
    fontFamily: defaultFontFamily,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          input: {
            height: '24px',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: defaultFontFamily,
          lineHeight: `${themeBaseSpacing * 3}px`,
          backgroundColor: Colors.night,
          padding: `${themeBaseSpacing}px ${themeBaseSpacing * 2}px`,
          fontSize: 14,
          color: Colors.white,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'underlined' },
          style: {
            fontWeight: 'normal',
            borderBottom: `1px solid ${Colors.night}`,
            textTransform: 'none',
            boxShadow: 'none',
            minWidth: 0,
            borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
            padding: `${themeBaseSpacing / 2}px ${themeBaseSpacing / 2}px`,
            '&:hover': {
              backgroundColor: alpha(Colors.lavender, 0.1),
            },
          },
        },
        {
          props: { variant: 'underlined', color: 'primary' },
          style: {
            color: Colors.primary,
            borderBottomColor: Colors.primary,
            '&:hover': {
              backgroundColor: alpha(Colors.primary, 0.1),
            },
          },
        },
      ],
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: 2,

          ...(ownerState.variant === 'outlined' && {
            backgroundColor: '#FFF',

            ...(ownerState.color === 'secondary' && {
              color: Colors.night,

              '&:hover': {
                backgroundColor: alpha('#FFF', 0.88),
              },
            }),
          }),
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: Colors.storm,
        },
      },
    },
  },
});
