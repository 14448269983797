import Konva from 'konva';
import { GlyphElement, OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { ArrowGlyph } from '../glyphs/ArrowGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { getPlayerReferences, isFrameInRange } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
};

export class PlayerVerticalArrow extends OverlayElementGlyphs {
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;
  playerShapes: ArrowGlyph[];
  playersIds: string[];

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const lineGlyphConfig = config.glyphs[0];

    this.endFrame = endFrame;
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.startFrame = startFrame;
    this.priority = config.priority;
    this.playersIds = getPlayerReferences(this.references);
    this.playerShapes = this.playersIds.map((playerId) => {
      return new ArrowGlyph({
        opacity: lineGlyphConfig.alpha ?? 1,
        size: lineGlyphConfig.size,
        colorPrimary: lineGlyphConfig.colorPrimary,
        dashed: lineGlyphConfig.element === GlyphElement.DASHED_LINE,
        id: playerId,
      });
    });
  }

  addToLayer(layer: Konva.Layer) {
    this.playerShapes.forEach((player) => {
      layer.add(player.shape);
    });
  }

  update({ playersPositions, scale, frame }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this)) return;
    this.excludedPlayerIdsFromRender.some((p) => this.playersIds.includes(p)) ? this.hide() : this.show();

    const players = playersPositions[frame];
    const lastFramePlayers = playersPositions[this.endFrame];

    this.playerShapes.forEach((player) => {
      if (!players[player.shape.id()]) return player.shape.hide();

      player.update(
        [
          players[player.shape.id()],
          {
            x: lastFramePlayers[player.shape.id()].x,
            y: players[player.shape.id()].y,
          },
        ],
        scale,
      );
    });
  }

  removeFromLayer = () => {
    this.playerShapes.forEach((player) => player.shape.destroy());
  };

  show = () => {
    this.playerShapes.forEach((player) => player.shape.show());
    this.isVisible = true;
  };

  hide = () => {
    this.playerShapes.forEach((player) => player.shape.hide());
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
